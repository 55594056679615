import { IUserRoles, IWorker } from './user.model';

export interface IWork {
	id: number
	slug: string,
	author: number,
	date: string,
	acf: {
		customer_info: ICustomerInfo,
		date: string,
		foreman_info: IForemanInfo,
		state: IWorkState
	}
}

export interface ICompletedWork {
	created_date: string,
	date: string,
	id: number,
	state: IWorkState,
	status: IWorkStatus,
	time: string,
	tips: string,
	total_time: string,
	worker: IAssignedWorker,
	workers: IAssignedWorker[],
	workers_count: string
}

export interface IRCompletedWork {
	name: string,
	works: ICompletedWork[]
}

export interface ICustomerInfo {
	bedroom: IBedrooms,
	contact_email: string,
	contact_name: string
	contact_phone: string,
	customer_email: string
	customer_name: string,
	customer_phone: string,
	dropoff_address: IAddress[],
	heavyItems: string,
	howfrom: string,
	medium_boxes: number,
	movers: ICrewSize,
	note: string,
	packing: string,
	payment: string,
	pickup_address: IAddress[],
	result: string,
	small_boxes: number,
	supplies: string,
	time: string,
	truck: ITruckSize,
	truck_fee: string,
	typeofresidency: string,
	wrapping_paper: number
}

export interface IForemanInfo {
	add_items: IAddItem[] | null,
	discount: string,
	double_drive: boolean
	drive_time: string
	driving_time_fee: boolean
	driving_time_price: string
	heavy_items: IHeavyItem[] | null,
	loading_flights: string
	loading_time: string
	notes: string
	payment: string,
	status: IWorkStatus,
	tips: number,
	total: number,
	total_time: number,
	truck: { value: string, label: string }[],
	truck_fee: boolean,
	unloading_flights_: number,
	unloading_time: string,
	workers: IAssignedWorker[] | null,
	workers_count: number
}

export enum IWorkState {
	PENDING = 'pending',
	CONFIRMED = 'confirmed',
	ASSIGN_WORKERS = 'assignWorkers',
	COMPLETED = 'completed'
}

export enum IWorkStatus {
	PENDING = 'pending',
	CONFIRMED = 'confirmed'
}

export enum IBedrooms {
	STUDIO = 'Studio',
	ONE = '1 bedroom',
	TWO = '2 bedroom',
	THREE = '3 bedroom',
	FOUR = '4 bedroom',
	FIVE = '5 bedroom',
	SIX = '6 bedroom'
}

export interface IAddress {
	full_address: string,
	unit: string,
	zip: string
}

export enum ICrewSize {
	TWO = '2 movers',
	THREE = '3 movers',
	FOUR = '4 movers',
	FIVE = '5 movers',
	SIX = '6 movers'
}

export enum ITruckSize {
	NO_TRUCK = 'No truck just a labor',
	SIXTEEN = '16 feet',
	TWENTY = '20 feet',
	TWENTY_SIX = '26 feet'
}

export interface IAddItem {
	add_item: string,
	add_item_count: number
}

export interface IHeavyItem {
	heavy_item: string,
	heavy_item_price: number
}

export interface IAssignedWorker {
	worker: IWorker,
	payment_type: string,
	worker_role: IUserRoles.FOREMAN | IUserRoles.HELPER,
	salary: number,
	status: boolean
}