import React, { useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Spin } from 'antd';
import { MyCalendar, MyModal } from 'components';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { IKeys, IWork } from 'models';
import { workService } from 'services';
import { SlotInfo } from 'react-big-calendar';

export const Schedule = () => {
	const [work, setWork] = useState<IWork | null>(null);
	const [slot, setSlot] = useState<SlotInfo | null>(null);
	const [range, setRange] = useState({
		startd: dayjs().startOf('month').format('YYYYMMDD'),
		endd: dayjs().endOf('month').format('YYYYMMDD')
	});

	const { data, isLoading } = useQuery({
		queryKey: [IKeys.WORKS, { range }],
		queryFn: () => workService.getAll({
			per_page: 100,
			notpending: true,
			...range
		})
	});

	const onRangeChange = (date: Date[] | { start: Date; end: Date }) => {
		if (!Array.isArray(date)) {
			setRange({
				startd: dayjs(date.start).format('YYYYMMDD'),
				endd: dayjs(date.end).format('YYYYMMDD')
			});
		} else {
			setRange({
				startd: dayjs(date[0]).format('YYYYMMDD'),
				endd: dayjs(date[date.length - 1]).format('YYYYMMDD')
			});
		}
	};

	const handleCancel = () => {
		setWork(null);
		setSlot(null);
	};

	return (
		<Spin spinning={isLoading}>
			<MyCalendar
				data={data?.data || []}
				setWork={setWork}
				setSlot={setSlot}
				onRangeChange={onRangeChange}
			/>
			<MyModal
				work={work}
				slot={slot}
				handleCancel={handleCancel}
				queryKey={[IKeys.WORKS, { range }]}
			/>
		</Spin>
	);
};

