const transform = (arr: string[]) => {
	return arr.map((el) => ({
		value: el,
		label: el
	}));
};

export const moversS = transform(['2 movers', '3 movers', '4 movers', '5 movers', '6 movers']);

export const trucksS = [
	{ value: 'sixteen0', label: '0 - 16ft' },
	{ value: 'twentySix1', label: '1 - 26ft' },
	{ value: 'twentySix2', label: '2 - 26ft' },
	{ value: 'twentySix3', label: '3 - 26ft' },
	{ value: 'twenty4', label: '4 - 20ft' },
	{ value: 'twenty5', label: '5 - 20ft' },
	{ value: 'sixteen6', label: '6 - 16ft' }
];

export const paymentS = transform(['cash', 'Credit card', 'Venmo', 'Zelle']);

export const bedroomS = transform([
	'Studio',
	'1 bedroom',
	'2 bedroom',
	'3 bedroom',
	'4 bedroom',
	'5 bedroom',
	'6 bedroom'
]);

export const truckSizeS = transform(['No truck just a labor', '16 feet', '20 feet', '26 feet']);

export const typeOfResS = transform(['Apartment', 'House', 'Townhouse', 'Storage', 'Office', 'Other']);

export const heavyItemsS = transform(['Piano', 'Safe', 'Other', 'No']);

export const packingS = transform(['No Packing', 'Partial Packing', 'Full Packing']);

export const referralS = transform(['Yelp', 'Google', 'Referral', 'Thumbtack', 'Other']);

export const timeRange = transform([
	'0:15',
	'0:30',
	'0:45',
	'1:00',
	'1:15',
	'1:30',
	'1:45',
	'2:00',
	'2:15',
	'2:30',
	'2:45',
	'3:00',
	'3:15',
	'3:30',
	'3:45',
	'4:00',
	'4:15',
	'4:30',
	'4:45',
	'5:00',
	'5:15',
	'5:30',
	'5:45',
	'6:00',
	'6:15',
	'6:30',
	'6:45',
	'7:00',
	'7:15',
	'7:30',
	'7:45',
	'8:00',
	'8:15',
	'8:30',
	'8:45',
	'9:00',
	'9:15',
	'9:30',
	'9:45',
	'10:00',
	'10:15',
	'10:30',
	'10:45',
	'11:00',
	'11:15',
	'11:30',
	'11:45',
	'12:00',
	'12:15',
	'12:30',
	'12:45'
]);
